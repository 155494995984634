<template>
  <div id="detail">
    <!-- 头部 -->
    <div class="QuestionHeader">
      <div class="QuestionHeader-content">
        <div class="QuestionHeader-main">
          <div class="QuestionHeader-title">{{ msg.TopicTitle }}测试测试</div>
          <div class="QuestionHeader-detail">
            <!-- <span v-html="msg.TopicContent"></span> -->
            <!-- <div class="QuestionHeader-more" @click="showMore" v-if="isShowMore&&msg.TopicContent">
              <span>显示全部</span>
              <span class="iconfont icon-arrow-down"></span>
            </div> -->
          </div>

          <!-- <feed-item :feedList="msg"></feed-item> -->

          <feed-content
            ref="feedContent"
            v-if="true"
            :author="msg"
            :summary="keyword"
            :content="msg.TopicContent"
            :keyword="keyword"
            :isRead="true"
          ></feed-content>
          <feed-actions
            v-if="true"
            @changeReview="isReview = !isReview"
            :actions="msg"
            :id="msg.Id"
          ></feed-actions>
        </div>
      </div>
    </div>
    <!-- 编辑器 -->
    <!-- <div class="AnswerWrap" v-if="isAsk">
      <div class="AnswerHeader">
        <img :src="user.headUrl" alt class="AuthorAvator" />
        <span class="AuthorName">{{ user.name }}</span>
      </div>
      <editor @change="contentChange" :isClear="isClear"></editor>
      <button class="AnswerButton" @click="submitAnswer">提交回答</button>
    </div> -->

    <!-- 回答 -->
    <div class="QuestionMain">
      <div class="List">
        <div class="ListHeader">精彩评论</div>
        <transition name="fade">
          <comment
            v-if="isReview"
            :sectionId="msg.SectionId"
            :id="msg.Id"
          ></comment>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import FeedItem from "@/components/bbs/FeedItem.vue";
import FeedContent from "@/components/bbs/FeedContent.vue";
import FeedActions from "@/components/bbs/FeedActions.vue";
import Comment from "@/components/bbs/Comment.vue";
import QuestionList from "@/components/bbs/QuestionList.vue";
import Editor from "@/components/bbs/Editor.vue";
import util from "@/utils/index.js";
import { queryAllTopicList, updateTopicReadNum ,queryStudentInfoById} from "@/api/bbs";

let detail, id;
export default {
  name: "detail",
  components: {
    Editor,
    QuestionList,
    FeedItem,
    FeedContent,
    FeedActions,
    Comment,
  },
  data() {
    return {
      followTip: "关注问题",
      user: {},
      msg: {},
      isShowMore: true,
      isAsk: false,
      eidtor: "",
      isClear: false,
      keyword: "",
      isReview: false,
    };
  },
  created() {
    // id = this.$route.query.id;
    this.getDetailMsg();
    var that = this;
    window["callByAndroidParam"] = function (jsonStr) {
      if (jsonStr != "") {
        that.user.Id = jsonStr.data;
        that.getQueryStudentInfoById();
      } else {
        this.$message.error("登录失败");
      }
    };
  },
  mounted() {
    // this.getContent();
    this.user.Id = this.$route.query.UserId>0?this.$route.query.UserId:0;
    this.sectionName = this.$route.query.SectionName == undefined?"":this.$route.query.SectionName;
    if (localStorage.getItem("userInfo") != null && localStorage.getItem("userInfo") !=undefined) {
      this.user = JSON.parse(localStorage.getItem("userInfo"));
    } else if(this.user.Id>0){
      this.getQueryStudentInfoById(); 
    }else{
      this.user.Id = 0;
    }
    this.postQuerySectionList();
    this.postQueryAllTopicList();
    // this.listenScroll(document.documentElement);
    window.userIDResultByIos = this.userIDResultByIos;
  },
  methods: {
    userIDResultByIos(jsonStr) {
      if (jsonStr != "") {
        this.user.Id = jsonStr.data;
        this.getQueryUserInfo();
      } else {
        this.$message.error("登录失败");
      }
    },
    callApp() {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (window.android != null && typeof window.android != "undefined") {
        //可以传递自定义数据，比如json
        window.android.callAndroid(JSON.stringify({action: "getUserID"}));
      } else if (isiOS) {
        window.webkit.messageHandlers.callIosGetUserID.postMessage(JSON.stringify({action: "callIosGetUserID"}));
      }
    },
    //根据Id获取学生信息
    async getQueryStudentInfoById() {
      const res = await queryStudentInfoById(this.user.Id);
      this.user = res.response;
      console.log(this.user);
      localStorage.setItem("userInfo",JSON.stringify(this.user));
    },
    // 列表数据
    async getDetailMsg() {
      let parm = {};
      parm.pageIndex = this.recPage;
      parm.pageSize = this.pageSize;
      parm.topicId = this.$route.query.id;
      const res = await queryAllTopicList(parm);
      if (res.data.length > 0) {
        console.log(res.data[0]);
        detail = res.data[0];
        this.msg = res.data[0];
        this.isReview = true;
      }
      await updateTopicReadNum(this.$route.query.id);
    },
    //监听编辑器
    contentChange(data) {
      console.log(data);
      this.eidtor = data;
    },
    //显示更多
    showMore() {
      this.summary = detail;
      this.isShowMore = false;
    },
    //写回答
    writeAnswer() {
      if (util.isLogin()) {
        this.isAsk = true;
      } else {
        this.callApp();
      }
    },
    //提交回答
    submitAnswer() {
      let params = {
        content: this.eidtor,
        qid: id,
      };
      this.axios.post("/answer/add", params).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: "回答成功",
            type: "success",
          });
          this.isClear = true;
          this.getDetailMsg();
        }
      });
    },
    //关注问题
    focusQuestion() {
      if (this.msg.isFollow) {
        this.axios
          .get(`/follow/question_cancel?id=${this.msg.id}`)
          .then((res) => {
            if (res.status == 200) {
              this.msg.isFollow = !this.msg.isFollow;
              this.followTip = "关注问题";
            }
          });
      } else {
        this.axios.get(`/follow/question?id=${this.msg.id}`).then((res) => {
          if (res.status == 200) {
            this.msg.isFollow = !this.msg.isFollow;
            this.followTip = "已关注";
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" >
@import "../../../assets/css/config";
// 头部
.QuestionHeader {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1);
  padding: 10px 0;
  &-content {
    display: flex;
    justify-content: space-between;
    // width: 1200px;
    height: 100%;
    padding: 0 16px;
    margin: 0 auto;
  }
  &-main {
    width: 1000px;
  }
  &-title {
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 22px;
    font-weight: 600;
    color: #1a1a1a;
  }
  &-detail {
    cursor: pointer;
    font-size: 15px;
  }
  &-more {
    display: inline-block;
    font-size: 14px;
    color: $fontColor;
  }
  &-side {
    display: flex;
    width: 200px;
    justify-content: flex-end;
    .QuestionStatus-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
      .QuestionStatus-name {
        font-size: 14px;
        color: $fontColor;
      }
      .QuestionStatus-num {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .QuestionStatus-split {
      width: 1px;
      background: #ebebeb;
      height: 50px;
    }
  }
  &-footer {
    margin-top: 8px;
    &-inner {
      display: flex;
      align-items: center;
      // width: 1200px;
      height: 100%;
      padding: 0 16px;
      margin: 0 auto;
    }
    .QuestionButton-groups {
      display: flex;
      align-items: center;
      .QuestionButton-follow {
        cursor: pointer;
        color: #ffffff;
        background: $mainColor;
        padding: 0 16px;
        line-height: 34px;
        height: 34px;
        margin-right: 10px;
        border: 1px solid $mainColor;
      }
      .AttentionButton {
        background: $fontColor;
        border: 1px solid $fontColor;
      }
      .QuestionButton-ask {
        cursor: pointer;
        padding: 0 16px;
        line-height: 34px;
        height: 34px;
        border: 1px solid $mainColor;
        background: #ffffff;
        color: $mainColor;
        &:hover {
          background: #e8f3ff;
        }
      }
      .icon-pen {
        font-size: 14px;
      }
    }
    .QuestionHeader-actions {
      display: flex;
      margin-left: 12px;
      .ActionsWrap {
        cursor: pointer;
        font-size: 14px;
        color: $fontColor;
        padding: 0 8px;
        &:hover {
          color: #677081;
        }
      }
      .ActionsText {
        margin-left: 4px;
      }
    }
  }
}
// 编辑器
.AnswerWrap {
  width: 1000px;
  margin: 10px auto;
  padding: 0 16px 60px 16px;
  background: #ffffff;
  .AnswerHeader {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    padding: 16px 0;
    .AuthorAvator {
      margin: 0 10px;
      width: 30px;
      height: 30px;
    }
  }
  .AnswerButton {
    margin-top: 8px;
    padding: 0 16px;
    background: $mainColor;
    color: #ffffff;
    line-height: 36px;
    float: right;
    cursor: pointer;
  }
}
// 回答
.QuestionMain {
  // width: 1200px;
  margin: 10px auto;
  // padding: 0 16px;
  background: #ffffff;
  .List {
    padding: 0 20px;
    .ListHeader {
      height: 50px;
      border-bottom: 1px solid #f6f6f6;
      line-height: 50px;
      font-weight: 600;
    }
    .ListItem {
      padding: 16px 0;
      border-bottom: 1px solid #ebebeb;
    }
    .Comment {
      .Comment-footer {
        .CommentReply-button {
          height: 30px !important;
          line-height: 30px !important;
          margin-right: 20px;
        }
      }
    }
  }
}
#detail img{
  max-width: 100%;
}
</style>

